import React from 'react'

const ImageModal = ({ children, closeModal, modalState }) => {
  if(!modalState) {
    return null;
  }
  
  return(
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-content" style={{overflow: "hidden"}}>
        {children}
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
    </div>
  );
}

export default ImageModal