import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment-timezone'
import { ClickableImage } from '../components/Gallery'

const Performance = ({ performance, show }) => {

    var formatted_date = moment(performance.date).tz('Europe/London').format(`Do MMMM, h.mma`);
    
    const name = show.frontmatter.title + ' - ' + formatted_date;
    
    var typesString = null;
    
    if (performance.ticket_types && performance.ticket_types.length) {
        typesString = performance.ticket_types.map(t => (
            t.type + (t.price_change ? '[' + (t.price_change > 0 ? '+' : '') + t.price_change + ']' : '')
        )).join('|');
    }
              
    return (
        <div className="content has-text-centered column is-one-third">
            <h5 className="has-text-weight-bold">
                <a className="has-text-primary" href={`https://www.google.com/maps/search/${performance.location}`} target="_blank" rel="noopener noreferrer">
                    {performance.location}
                </a>
            </h5>
            <h6 className="is-italic is-size-7">{formatted_date}</h6>
            <button className="snipcart-add-item button has-background-highlight has-text-white"
                            data-item-name={name}
                            data-item-id={name.replace(/[\s.]/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}
                            data-item-price={performance.ticket_cost}
                            data-item-url={show.fields.slug}
                            
                            
                            data-item-description="Your ticket will be available on the door under your name, or name of the person whose card was used for payment."
                            data-item-shippable={false}
                            
                            data-item-custom1-name={typesString ? 'Type' : null}
                            data-item-custom1-options={typesString}
                          >Buy Tickets</button>
        </div>
    )
}

const Performances = ({ performances, show }) => (
  <div className="columns is-centered is-multiline">
    {performances.map((performance) => (
        <Performance key={show.id + performance.date} performance={performance} show={show}/>
    ))}
  </div>
)

const ShowPerformances = ({ shows }) => (
    <div>
        {shows.map((show, i) => {
            const titleAndPerfs = <React.Fragment>
                    <Link className="has-text-weight-bold is-size-3 content has-text-primary has-text-centered" to={show.fields.slug}>{show.frontmatter.title}</Link>
                    <Performances performances={show.future_perfs} show={show}/>
                </React.Fragment>
            return <div key={i} className="is-flex upcoming-show-list">
                {show.frontmatter.small_image ?
                    <React.Fragment>
                        <div className="columns is-centered">
                            <div className="column is-one-quarter">
                                <ClickableImage fluid={show.frontmatter.small_image.childImageSharp.small} full_src={show.frontmatter.small_image.childImageSharp.full.src} caption={show.frontmatter.title} className="show-shadow-image"/>
                            </div>
                            <div className="is-flex column" style={{flexDirection: 'column', justifyContent: 'center'}}>
                                {titleAndPerfs}
                            </div>
                        </div>
                    </React.Fragment>
                : titleAndPerfs}
            </div>
        })}
    </div>
)

export default ShowPerformances
export {Performances}