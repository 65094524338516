import React from 'react'
import Img from 'gatsby-image'
import ImageModal from './ImageModal'

class ClickableImage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
          modalState: false
        };
        
        this.toggleModal = this.toggleModal.bind(this);
    }
    
    toggleModal() {    
        this.setState((prev, props) => {
            const newState = !prev.modalState;
            
            return { modalState: newState };
        });
    }
    
    render() {
        var { fluid, full_src, caption, className } = this.props; 
        return (
            <React.Fragment>
                <div className={'clickable-image ' + className} onClick={this.toggleModal}>
                    <Img fluid={fluid} alt={caption} />
                </div>
                <ImageModal closeModal={this.toggleModal} modalState={this.state.modalState} >
                    <img src={full_src} alt={caption} />
                </ImageModal>
            </React.Fragment>
        )
    }
}

const Gallery = ({ images }) => (
    <div className="columns is-centered is-multiline is-mobile is-1 is-variable is-variable-vertical">
        {images.map((img, i) => (
            <ClickableImage key={i} fluid={img.image.childImageSharp.square} full_src={img.image.childImageSharp.full.src} caption={img.caption} className="column is-one-third-tablet is-half-mobile gallery-image" />
        ))}
    </div>
)

export default Gallery;

export { ClickableImage };