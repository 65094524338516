import React from 'react'
import Img from 'gatsby-image'

export const TeamMember = ({member, right, image_filter}) => {
  var image = member.image ? <div className="is-narrow" style={{padding: '16px'}}>
            <div className="image has-text-centered">
              <Img fixed={member.image.childImageSharp.fixed} style={{filter: image_filter}}/>
            </div>
          </div> : null;
  
  return (
    <div className="columns is-vcentered" style={{borderBottom: '1px solid hsla(0,0%,85.9%,.5)', marginBottom: '1rem' }}>
      { !right && image }
      <div className="column">
        <div className="content" style={{paddingLeft: '0'}}>
          <p className="has-text-justified">
            <span className="has-text-weight-bold">{member.name}</span>
            <br />
            {member.role ?
              <React.Fragment>
                  <span className="is-italic">{member.role}</span>
                  <br />
              </React.Fragment>
            : null}
            {member.bio}
          </p>
        </div>
      </div>
      { right && image }
    </div>
  )
}


export const TeamSection = ({members, title, showTitle, image_position, image_filter}) => <section className="section teamSection">
  {title && showTitle ? 
    <h1 className="title">{title}</h1>
  : null }
  {members.map((m, i) => (
            <TeamMember member={m} key={i} right={image_position === 'alternate' ? i % 2 : (image_position === 'right' ? true : false)} image_filter={image_filter}/>
          ))}
</section>