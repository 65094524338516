import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import { Performances } from '../components/ShowPerformances'
import ContentPadding from '../components/ContentPadding'
import Gallery from '../components/Gallery'
import Review from '../components/Review'
import Img from 'gatsby-image'
import { ClickableImage } from '../components/Gallery'
import ColoredHeader from '../components/ColoredHeader'
import { TeamSection } from '../components/TeamSection'

export class ShowTemplate extends React.Component {
  render() {
    const {content, title, description, small_image, tags, future_perfs, gallery, show, reviews, team_sections} = this.props;
    
    return (
      <React.Fragment>
        <ContentPadding>
          <section className="section">
            <div className="columns is-centered is-mobile is-multiline">
                
                <div className="column is-three-quarters-tablet is-full-mobile">
                  <h1 className="title is-size-2 has-text-weight-bold is-bold-light">{title}</h1>
                  {description ? <p>{description}</p> : null}
                  <HTMLContent content={content} className="has-text-justified content" />
                </div>
                <div className="column is-one-quarter-table is-half-mobile">
                    <ClickableImage fluid={small_image.childImageSharp.small} full_src={small_image.childImageSharp.full.src} caption={title} className="show-shadow-image"/>
                </div>
            </div>
            
            
            {future_perfs && future_perfs.length ? (
              <React.Fragment>
                <h2 className="has-text-weight-bold is-size-2">Upcoming Shows</h2>
                <Performances performances={future_perfs} show={show} />
              </React.Fragment>
            ) : null}
          </section>
        </ContentPadding>
                
        {team_sections && team_sections.length ? 
          <React.Fragment>
            <ColoredHeader>Cast</ColoredHeader>
            <ContentPadding>
              {team_sections.map((ts, i) => 
                <TeamSection key={i} title={ts.title} showTitle={ts.show_title} image_position={ts.image_position} image_filter={ts.image_filter} members={ts.members} />
              )}
            </ContentPadding>
          </React.Fragment>
        : null}
        
        <Review reviews={reviews} />
        
        {(gallery && gallery.length) || (tags && tags.length) ?
          <ContentPadding>
            <section className="section">
              {gallery && gallery.length ? (
                <Gallery images={gallery} />
              ) : null}
              
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </section>
          </ContentPadding>
        : null }
      </React.Fragment>
    );
  }
}

const Show = ({ data }) => {
  const { markdownRemark: show } = data

  return (
    <Layout>
      <Helmet title={`Voices Across Time | ${show.frontmatter.title}`} />
      <Img className={'header-img crop-focus-' + show.frontmatter.header_image_crop_focus} fluid={show.frontmatter.full_image.childImageSharp.fluid} alt="" />
      <ShowTemplate
        content={show.html}
        title={show.frontmatter.title}
        description={show.frontmatter.description}
        tags={show.frontmatter.tags}
        future_perfs={show.frontmatter.performances.filter(p => p.date && new Date(p.date) > new Date())}
        gallery={show.frontmatter.gallery}
        show={show}
        reviews={show.frontmatter.reviews}
        small_image={show.frontmatter.small_image}
        team_sections={show.frontmatter.team_sections}
      />
    </Layout>
  )
}

export default Show

export const pageQuery = graphql`
  query ShowByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
       slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        full_image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        header_image_crop_focus
        small_image {
          childImageSharp {
            small: fluid(maxWidth: 400, maxHeight: 565, quality: 75, cropFocus: CENTER, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth:1344, quality: 90, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        performances{
          date
          location
          ticket_cost
          ticket_types {
            type
            price_change
          }
        }
        gallery {
          image {
            childImageSharp {
              square: fluid(maxWidth:400, maxHeight: 400, quality: 75, cropFocus: NORTH, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth:1344, quality: 90, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        reviews {
          review
          author
        }
        team_sections {
          title
          show_title
          image_position
          image_filter
          members {
            name
            role
            bio
            image  {
              childImageSharp {
                fixed(width: 200, height: 200, quality: 90, toFormat: JPG) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
