/* eslint-disable */
import React from 'react'
import { Link } from 'gatsby'
import ContentPadding from '../components/ContentPadding'

const Review = ({ reviews }) => {
  if (reviews && reviews.length) {
    const quote = reviews[Math.floor(Math.random() * reviews.length)];
  
    return (
      <section className="section has-background-light-color">
        <ContentPadding>
          <div className="has-text-white has-text-centered">
              <div className="is-size-2 review-quote">
                &ldquo;{quote.review}&rdquo;
              </div>
              <div>
                - {quote.author}
              </div>
          </div>
        </ContentPadding>
      </section>
    );
  }
  
  return null
}

export default Review
